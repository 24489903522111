<template>
    <div class="transition-all duration-500" v-bind:class="{
        'mt-3 mb-px' : !noMargin,
        'visible opacity-100' : elementActive,
        'invisible opacity-25' : !elementActive,
    }">
        <slot name="label">
            <label v-if="label" class="block mb-2">
                {{label}}
                <span class="inline-block text-gray-400" v-if="required">*</span>
            </label>
        </slot>
        <div class="relative flex">
            <input 
                @input="handleInput" :value="modelValue" @blur="inputFocusLeave"
                :type="internalType" :placeholder="placeholder" :disabled="disabled"  :autofocus="autofocus" :readonly="readonly" step="any"
                class="userdata appearance-none border transition-all shadow duration-500 w-full py-3 px-5 text-gray-700 dark:text-white dark:bg-gray-600 leading-tight focus:outline-none focus:shadow-outline"
                v-bind:class="{ 
                    'border-gray-100 dark:border-gray-300': !elementActive && !error.show,
                    'border-gray-300 dark:border-gray-500 ': elementActive && !error.show,
                    'mb-3': !noMargin,

                    'rounded' : !first && !second,
                    'rounded-l' : first,
                    'rounded-r' : second,

                    'pr-10': internalType === 'number' && hasHelper,
                    'text-right': type === 'chf',

                    'hasHelpText' : hasHelper,

                    'border-red-700 dark:border-red-400 shadow-red-400 dark:shadow-orange-800' : error.show,
                }" />
                <div v-if="type == 'chf'" class="shrink-0 mb-3 h-12 flex items-center gap-1.5 p-3 bg-blue-50 rounded-r-md border border-l-0 dark:bg-slate-900 dark:border-gray-500">
                    CHF
                    <LazyElementsHelpBubble v-if="path" :path="path" @helper="hasHelper = true" />
                </div>
                <div v-else class="absolute top-2 right-2 flex gap-0.5">
                    <ElementsIcon :icon="internalType == 'password' ? 'password-show' : 'password-hide'" size="big"
                        class="p-2 transition-all duration-200 cursor-pointer hover:bg-gray-200 dark:hover:bg-slate-800 rounded-full"
                        @click="internalType =  internalType == 'password' ? 'text' : 'password'" v-if="type == 'password'" />
                    <LazyElementsHelpBubble v-if="path" :path="path" @helper="hasHelper = true" />
                </div>
        </div>
            <slot name="helper">
                <p class="text-sm w-full text-gray-400" v-if="error.show && error.message && type == 'password'">{{ error.message }}</p>
            </slot>
    </div>
</template>
<script setup>
const props = defineProps({
    type: { type: String, default: 'text' },
    path: { type: String, default: '' },
    component: { type: String, default: '' },
    label: { type: String },
    modelValue: { default: null },
    placeholder: { type: String },
    disabled: { type: String },
    required: { type: Boolean, default: false },
    autofocus: { type: String },
    readonly: { type: Boolean, default: false },
    noMargin: { type: Boolean, default: false },
    first: { type: Boolean, default: false },
    second: { type: Boolean, default: false },
    validation: { type: Object, default: { autovalidate: () => {}, add: () => {}, remove: () => {} } },
})
const emit = defineEmits(['update:modelValue', 'error'])

const internalType = ref(props.type == 'chf' ? 'number' : props.type)
const hasHelper = ref(false)

const error = reactive({
    show: false,
    message: '',
})
const handleInput = (e) => {
    emit('update:modelValue', e.target.value)
    props.validation.autovalidate()
}
const inputFocusLeave = () => {
    if(props.type == 'chf') {
        if(props.modelValue == null) return
        emit('update:modelValue', parseFloat(props.modelValue).toFixed(2))
        props.validation.autovalidate()
    }
}
inputFocusLeave()

const requiredValidation = () => useHandleValidation(internalType.value == 'number' ? !Number.isNaN(props.modelValue) && props.modelValue !== '' : props.modelValue?.length > 0, 'Dieses Feld ist erforderlich', error, emit)

const emailValidation = (previous) => {
    let emailCheck = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i
    return useHandleValidation(props.modelValue === undefined || props.modelValue === '' || props.modelValue?.length == 0 || emailCheck.test(props.modelValue), 'Diese E-Mail Adresse ist ungültig', error, emit, previous)
}

const urlValidation = (previous) => {
    let domainCheck = /^([a-z\-]+)?:?(\/\/)?([a-zA-Z0-9\.]+)?(?:\d+)?$/i
    let url = props.modelValue
    if(props.modelValue?.startsWith('www.')) url = props.modelValue?.slice(4)
    console.log(props.modelValue, url)
    return useHandleValidation(props.modelValue === undefined || props.modelValue?.length == 0 || domainCheck.test(url), 'Diese Webseite ist ungültig', error, emit, previous)
}
const passwordValidation = (previous) => {
    let passwordCheck = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/g
    return useHandleValidation(props.modelValue === undefined || props.modelValue?.length == 0 || passwordCheck.test(props.modelValue), 'Mind. 8 Zeichen, mindestens je eine Zahl, ein Sonderzeichen, ein Gross- & ein Kleinbuchstabe', error, emit, previous)
}

const elementActive = ref(false)
onMounted(function(){
    setTimeout(function(){ elementActive.value = true }, 100)

    let path = (props.component ? props.component + '.' : '') + props.path

    if(props.required) props.validation.add(path, requiredValidation)

    if(props.type == 'email' && props.required) props.validation.add(path, emailValidation)

    if(props.type == 'website') props.validation.add(path, urlValidation)

    if(props.type == 'password') props.validation.add(path, passwordValidation)
})
onUnmounted(function(){
    props.validation.remove(props.path)
})
onBeforeUnmount(() => elementActive.value = false )
</script>
<style>
.hasHelpText::-webkit-calendar-picker-indicator {
    margin-right: 1.32rem;
}
</style>